import React, { useEffect, useState } from 'react'
import * as S from './styles'
import Input from '../../components/Input'
import InputAdornment from '@mui/material/InputAdornment'
import { MdSearch } from 'react-icons/md'
import DataGridUsers from '../../components/DataGrid'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/auth'
import { getUsers } from '../../services/users'
import Modal from './Modal'

function Users() {
  const navigate = useNavigate()

  const { user } = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'email',
      headerName: 'E-mail',
      align: 'center',
      headerAlign: 'center',
      width: 250
    },
    {
      field: 'action',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: params => {
        return (
          <S.WrapperActions>
            <S.ButtonEdit
              onClick={async () => {
                navigate('/users/edit', {
                  state: {
                    id: params.id,
                    name: params.row.name,
                    email: params.row.email,
                    assuntos: params.row.assuntos
                  }
                })
              }}
            />
            <S.ButtonDelete
              onClick={async () => {
                setSelectedUser(params.id)
                setShowModal(true)
              }}
            />
          </S.WrapperActions>
        )
      }
    }
  ]

  const [rows, setRows] = useState([])
  const [rowsUsers, setRowsUsers] = useState([])

  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (user._id) {
      getUsers(user?.ecossistema_fornecedor).then(response => {
        // console.log(response)
        setRowsUsers(response)
      })
    }
  }, [])

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  }

  const requestSearch = searchValue => {
    const regex = new RegExp(escapeRegExp(searchValue), 'i')
    const rowsFiltered = rowsUsers.filter(row => {
      return regex.test(row.name) || regex.test(row.email)
    })
    setRows(rowsFiltered)
  }

  useEffect(() => {
    if (searchText.length > 0) {
      requestSearch(searchText)
    }

    if (searchText.length === 0) {
      setRows(rowsUsers)
    }
  }, [searchText, rowsUsers])

  return (
    <S.Container>
      {showModal && (
        <Modal
          selectedUser={selectedUser}
          setShowModal={setShowModal}
          showModal={showModal}
          setRowsUsers={setRowsUsers}
        />
      )}

      <S.TitleContainer>
        <S.Title>Usuários</S.Title>
        <S.ButtonNewUser onClick={() => navigate('/users/add')}>
          Novo
        </S.ButtonNewUser>
      </S.TitleContainer>

      <Input
        value={searchText}
        onChange={e => {
          setSearchText(e.target.value)
        }}
        label="Pesquisar"
        id="input-with-icon-adornment"
        sx={{
          width: '330px'
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdSearch size={30} />
            </InputAdornment>
          )
        }}
      />

      <DataGridUsers rows={rows} columns={columns} />
    </S.Container>
  )
}

export default Users
