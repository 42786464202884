import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
// import { ButtonOutline } from '../../components/Buttons'
import { MdSend, MdAttachFile, MdFileDownload } from 'react-icons/md'
import { IoDocumentAttachOutline } from 'react-icons/io5'
import { AiOutlineCheck } from 'react-icons/ai'
import { DotLoader } from 'react-spinners'

import * as S from './styles'
import { useNavigate } from 'react-router-dom'

const BodyChat = ({ messages, visualizado }) => {
  const messagesEndRef = useRef(null)
  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    scrollToBottom()
  }, [messages])

  const convertToLocalHour = hora => {
    if (hora !== undefined) {
      const date = moment(hora).format('DD/MM/YYYY HH:mm:ss')
      const h = date.split(' ')
      return `${h[1].split(':')[0]}:${h[1].split(':')[1]}`
    }
  }

  const formatDateMessage = hora => {
    if (hora !== undefined) {
      const date = moment(hora).format('DD/MM/YYYY HH:mm:ss')
      return `${date.substring(0, 5)}`
    }
    return hora
  }

  const isToday = date => {
    return moment(date).isSame(moment(), 'day')
  }

  return (
    <S.Chat>
      {messages &&
        messages.map((message, idx) => {
          return (
            <div key={idx}>
              {message.primeira_mensagem_do_dia && (
                <S.DayBox>
                  <S.Day>
                    <S.TextDay>
                      {isToday(message.timestamp) && 'HOJE'}
                      {!isToday(message.timestamp) &&
                        formatDateMessage(message.timestamp)}
                    </S.TextDay>
                  </S.Day>
                </S.DayBox>
              )}

              <S.Message className={message.sender}>
                {message?.sender_name && (
                  <span className="sender_name">{message?.sender_name}</span>
                )}
                <div className={message.sender}>
                  <span className="span-message">
                    {message.url ? (
                      <a
                        href={message.url}
                        style={{
                          color: message.sender === 'admin' ? '#fff' : '#666',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <IoDocumentAttachOutline
                          style={{
                            color: message.sender === 'admin' ? '#fff' : '#666',
                            fontSize: 22,
                            marginRight: 10,
                            cursor: 'pointer'
                          }}
                        />
                        Baixar arquivo
                      </a>
                    ) : (
                      message.message
                    )}
                    <p className="hour">
                      {convertToLocalHour(message.timestamp)}
                    </p>
                  </span>
                </div>
                {message.sender === 'admin' &&
                  idx + 1 === messages.length &&
                  visualizado && (
                    <div className={message.sender}>
                      <span className="visualized">
                        Visto
                        <AiOutlineCheck
                          style={{
                            color: '#8CCC82',
                            marginLeft: 3,
                            marginRight: -5
                          }}
                        />
                        <AiOutlineCheck style={{ color: '#8CCC82' }} />
                      </span>
                    </div>
                  )}
              </S.Message>
            </div>
          )
        })}
      <div ref={messagesEndRef} />
    </S.Chat>
  )
}

export function Chat({
  chatDetails,
  messages,
  reloadMessages,
  sendMessage,
  visualizado_user,
  finalizeChat,
  finalizado,
  loading,
  setFile,
  confirmAttachment
}) {
  const [text, setText] = useState('')
  const [attachment, setAttachment] = useState(null)
  const [attachmentName, setAttachmentName] = useState('')
  const navigate = useNavigate()

  const inputAttachmentRef = useRef()

  // useEffect(() => {
  //   const fetchData = async () => {}
  //   fetchData()
  // }, [userReducer.user._id])

  const submit = e => {
    e.preventDefault()
    if(text.trim() === '' && attachment === null) return
    sendMessage(text)
    setText('')
    setAttachmentName('')
  }
  const handleAttachmentSelected = e => {
    const [file] = Array.from(e.target.files)
    if (file) {
      setFile(file)
      confirmAttachment(true)
    }
    inputAttachmentRef.current.value = ''
  }

  return (
    <S.Container>
      <S.LeftSide>
        <S.TopSide>{chatDetails?.usuario?.username}</S.TopSide>
        <BodyChat messages={messages} visualizado={visualizado_user} />
        <S.BotSide onSubmit={submit}>
          {finalizado ? (
            <>
              <MdAttachFile
                style={{ color: '#808080', fontSize: 22, marginRight: 10 }}
              />
              <S.SendMessage disabled placeholder="Chat finalizado" />
              <MdSend
                style={{ color: '#808080', fontSize: 22, marginLeft: 10 }}
              />
            </>
          ) : (
            <>
              <MdAttachFile
                style={{
                  color: '#7F5CD1',
                  fontSize: 22,
                  marginRight: 10,
                  cursor: 'pointer'
                }}
                onClick={() => inputAttachmentRef.current.click()}
              />
              <input
                type="file"
                onChange={handleAttachmentSelected}
                accept=".xlsx, .xls, .doc, .docx, .pdf, .jpg, .jpeg, .png, video/mp4, video/*"
                ref={inputAttachmentRef}
                style={{ display: 'none' }}
              />
              <S.SendMessage
                value={text}
                onChange={e => setText(e.target.value)}
                placeholder="Escreva a mensagem..."
              />
              {loading ? (
                <DotLoader
                  size={20}
                  color="#7F5CD1"
                  style={{ marginLeft: 10 }}
                />
              ) : (
                <MdSend
                  onClick={e => text.trim() === '' ? null : submit(e)}
                  style={{
                    color: text.trim() === '' ? '#808080' : '#7F5CD1',
                    fontSize: 22,
                    cursor: 'pointer',
                    marginLeft: 10
                  }}
                />
              )}
            </>
          )}
        </S.BotSide>
      </S.LeftSide>
      <S.RightSide>
        <S.TopSide style={{ justifyContent: 'center' }}>
          Detalhes do contato
        </S.TopSide>
        <S.DetailsContacts>
          {chatDetails?.assunto && (
            <S.Row>
              <S.TextSmall>Assunto: </S.TextSmall>
              <S.Text>{chatDetails?.assunto}</S.Text>
            </S.Row>
          )}
          {chatDetails?.codigo && (
            <S.Row>
              <S.TextSmall>Código: </S.TextSmall>
              <S.Text>{chatDetails?.codigo}</S.Text>
            </S.Row>
          )}
          {chatDetails?.usuario !== 'Anônimo' && (
            <S.Row>
              <S.TextSmall>Usuário: </S.TextSmall>
              <S.Text>{chatDetails?.usuario}</S.Text>
            </S.Row>
          )}
          {chatDetails?.nickname !== 'Anônimo' && (
            <S.Row>
              <S.TextSmall>Nome: </S.TextSmall>
              <S.Text>{chatDetails?.nickname}</S.Text>
            </S.Row>
          )}
          {chatDetails?.matricula !== 'Anônimo' && (
            <S.Row>
              <S.TextSmall>Matrícula: </S.TextSmall>
              <S.Text>{chatDetails?.matricula}</S.Text>
            </S.Row>
          )}
          {chatDetails?.email !== 'Anônimo' && (
            <S.Row>
              <S.TextSmall>Email: </S.TextSmall>
              <S.Text>{chatDetails?.email}</S.Text>
            </S.Row>
          )}
          {chatDetails?.telefone !== 'Anônimo' && (
            <S.Row>
              <S.TextSmall>Telefone: </S.TextSmall>
              <S.Text>{chatDetails?.telefone}</S.Text>
            </S.Row>
          )}
          {chatDetails?.empresa_filha !== 'Anônimo' &&
            chatDetails?.empresa_filha && (
              <S.Row>
                <S.TextSmall>Empresa: </S.TextSmall>
                <S.Text>{chatDetails?.empresa_filha}</S.Text>
              </S.Row>
            )}
          {chatDetails?.filial !== 'Anônimo' && chatDetails?.filial && (
            <S.Row>
              <S.TextSmall>Filial: </S.TextSmall>
              <S.Text>{chatDetails?.filial}</S.Text>
            </S.Row>
          )}
          {chatDetails?.departamento !== 'Anônimo' &&
            chatDetails?.departamento && (
              <S.Row>
                <S.TextSmall>Departamento: </S.TextSmall>
                <S.Text>{chatDetails?.departamento}</S.Text>
              </S.Row>
            )}
          {chatDetails?.cargo !== 'Anônimo' && chatDetails?.cargo && (
            <S.Row>
              <S.TextSmall>Cargo: </S.TextSmall>
              <S.Text>{chatDetails?.cargo}</S.Text>
            </S.Row>
          )}
          {chatDetails?.data_admissao !== 'Anônimo' &&
            chatDetails?.data_admissao && (
              <S.Row>
                <S.TextSmall>Data de Admissão: </S.TextSmall>
                <S.Text>{chatDetails?.data_admissao}</S.Text>
              </S.Row>
            )}
          {chatDetails?.dataPedido && (
            <S.Row>
              <S.TextSmall>Data do pedido: </S.TextSmall>
              <S.Text>{chatDetails?.dataPedido}</S.Text>
            </S.Row>
          )}
          {chatDetails?.testemunhas ? (
            <>
              <S.Row>
                <S.TextSmall
                  style={{ marginTop: 15, color: '#000', fontWeight: 'bold' }}
                >
                  Detalhes Assédio
                </S.TextSmall>
              </S.Row>
              {chatDetails?.denunciado ? (
                <S.Row>
                  <S.TextSmall>Denunciado: </S.TextSmall>
                  <S.Text>{chatDetails?.denunciado}</S.Text>
                </S.Row>
              ) : null}
              {chatDetails?.nome_completo ? (
                <S.Row>
                  <S.TextSmall>Denunciante: </S.TextSmall>
                  <S.Text>{chatDetails?.nome_completo}</S.Text>
                </S.Row>
              ) : null}
              {chatDetails?.matricula_denunciante ? (
                <S.Row>
                  <S.TextSmall>Matrícula do denunciante: </S.TextSmall>
                  <S.Text>{chatDetails?.matricula_denunciante}</S.Text>
                </S.Row>
              ) : null}
              {chatDetails?.anonimo ? (
                <S.Row>
                  <S.TextSmall>Anônimo: </S.TextSmall>
                  <S.Text>{chatDetails?.anonimo}</S.Text>
                </S.Row>
              ) : null}
              {chatDetails?.meio_ocorrencia ? (
                <S.Row>
                  <S.TextSmall>Meio de ocorrência: </S.TextSmall>
                  <S.Text>{chatDetails?.meio_ocorrencia}</S.Text>
                </S.Row>
              ) : null}
              {chatDetails?.periodicidade ? (
                <S.Row>
                  <S.TextSmall>Periodicidade: </S.TextSmall>
                  <S.Text>{chatDetails?.periodicidade}</S.Text>
                </S.Row>
              ) : null}
              {chatDetails?.persiste ? (
                <S.Row>
                  <S.TextSmall>Persiste ocorrendo: </S.TextSmall>
                  <S.Text>{chatDetails?.persiste}</S.Text>
                </S.Row>
              ) : null}
              {chatDetails?.provas ? (
                <S.Row>
                  <S.TextSmall>Possui provas: </S.TextSmall>
                  <S.Text>{chatDetails?.provas}</S.Text>
                </S.Row>
              ) : null}
              <S.Row>
                <S.TextSmall>Testemunhas: </S.TextSmall>
                <S.Text>{chatDetails?.testemunhas}</S.Text>
              </S.Row>
              {chatDetails?.informar_testemunha ? (
                <S.Row>
                  <S.TextSmall>Identificou testemunhas: </S.TextSmall>
                  <S.Text>{chatDetails?.informar_testemunha}</S.Text>
                </S.Row>
              ) : null}
            </>
          ) : null}
          {/* <S.TextSmall>Descrição do pedido de ajuda: </S.TextSmall> */}
          {/* <S.TextArea>{chatDetails?.descricao}</S.TextArea> */}
          <S.RowBetween>
            {/* <button
              style={{ height: 30, width: 100 }}
              label="Atualizar"
              eventClick={reloadMessages}
            /> */}
            {finalizado ? (
              <S.Button finalized={true}>Finalizado</S.Button>
            ) : (
              <S.Button onClick={() => finalizeChat(true)}>
                Finalizar contato
              </S.Button>
            )}
          </S.RowBetween>
        </S.DetailsContacts>
      </S.RightSide>
    </S.Container>
  )
}

export default Chat
